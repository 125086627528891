<script>
import { sameAs } from 'vuelidate/lib/validators';
import APIUser from '@app/services/API/User';

export default {
  head: {
    title: 'Sécurité - Mon compte',
  },
  data() {
    return {
      isLoading: false,
      user: {
        old_password: '',
        new_password: '',
        confirm_new_password: '',
      },
    };
  },
  validations: {
    user: {
      confirm_new_password: {
        sameAs: sameAs('new_password'),
      },
    },
  },
  methods: {
    handle() {
      const user = { ...this.user };

      this.isLoading = true;
      APIUser.updatePassword(user)
        .then(() => {
          this.$showMessage.success();
          Object.assign(this.user, this.$options.data.bind(this)().user);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Sécurité
      </h2>
      <p>
        Votre sécurité est primordiale pour nous !
      </p>
      <p>
        Les meilleurs mots de passe sont constituer de
        <strong>
          <li>12+ caractères si possible</li>
          <li>Majuscules et minuscules</li>
          <li>Caractères spéciaux</li>
          <li>Chiffres</li>
        </strong>
        ils sont plus durs à deviner par les hackers.
      </p>
      <p>
        Exemple : <strong>F0rmat!0n@2045</strong>
      </p>
    </div>

    <div class="column">
      <form ref="form" class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="columns is-multiline">
          <div class="column is-12">
            <b-field label="Ancien mot de passe">
              <b-input
                v-model="user.old_password"
                type="password"
                required
                password-reveal
                :use-html5-validation="false"
              />
            </b-field>
          </div>
          <div class="column is-12">
            <b-field label="Nouveau mot de passe">
              <b-input
                v-model="user.new_password"
                type="password"
                minlength="8"
                maxlength="32"
                required
                placeholder="8 caractères minimum"
                password-reveal
                :use-html5-validation="false"
              />
            </b-field>
          </div>
          <div class="column is-12">
            <b-field
              label="Confirmez votre nouveau mot de passe"
              v-bind="$getErrorProps($v.user.confirm_new_password, ['sameAs'])"
            >
              <b-input
                v-model="user.confirm_new_password"
                type="password"
                minlength="8"
                maxlength="32"
                required
                placeholder="8 caractères minimum"
                password-reveal
                :use-html5-validation="false"
              />
            </b-field>
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>
